import {
    AssignmentTurnedIn as AvailabilityIcon,
    Build as BuildIcon,
    History as HistoryIcon,
    LibraryAdd as LibraryAddIcon,
    RestaurantMenu as RestaurantMenuIcon,
    Settings as SettingsIcon,
} from "@material-ui/icons";
import React, { ReactNode } from "react";
import Home from "./components/Home";
import ForgotPasswordEmail from "./pages/Auth/ForgotPasswordEmail";
import Login from "./pages/Auth/Login";
import { Auth0Login } from "./pages/Auth/Auth0/Login";
import Profile from "./pages/Auth/Auth0/Profile";
import ResetPassword from "./pages/Auth/ResetPassword";
import ItemAvailability from "./pages/item-availability/ItemAvailability";
import { MenuEditorWrapper } from "./pages/menu-editor/MenuEditorWrapper";
import MenuIngestion from "./pages/menu-ingestion/MenuIngestion";
import MenuExport from "./pages/menuVersion/MenuExport";
import MenuVersion from "./pages/menuVersion/MenuVersion";
import OperationHours from "./pages/RestaurantSetting/OperationHours";
import RestaurantSettings from "./pages/RestaurantSetting/RestaurantSetting";
import TTSOperationHours from "./pages/RestaurantSetting/TTSOperationHours";
import UserManagementWrapper from "./pages/user-management";
import { RolePermissions } from "./types/restaurant";
import { readEnvVariable } from "./utils/helper-functions";
import { hasRoleAccess } from "./utils/restaurants";
import LimitedHome from "./pages/LimitedHome";

const {
    RestaurantManager,
    RestaurantStaff,
    PrestoCustomerSupport,
    Limited,
} = RolePermissions;

export interface AppRoute {
    title: string;
    path: string;
    protected: boolean;
    accessLevelNeeded?: RolePermissions;
    component: ReactNode;
    disableAppWrapper?: boolean;
    visibleTab?: boolean;
    originalPath?: string;
    subRoutes?: {
        [name: string]: AppRoute;
    };
    rootTab?: boolean;
}

export const ROUTES: { [name: string]: AppRoute } = {
    auth0Login: {
        title: "Login",
        path: "/auth0-login",
        protected: false,
        component: <Auth0Login />,
        disableAppWrapper: true,
    },
    auth0Auth: {
        title: "Profile",
        path: "/profile",
        protected: false,
        component: <Profile />,
        disableAppWrapper: true,
    },
    resetPassword: {
        title: "Reset Password",
        path: "/reset-password",
        protected: false,
        component: <ResetPassword />,
        disableAppWrapper: true,
    },
    home: {
        //Logged-in user isn't mapped to any restaurant groups
        title: "Home",
        path: "/home",
        protected: true,
        accessLevelNeeded: undefined,
        component: <Home />,
    },
    limitedHome: {
        title: "Limited Access",
        path: "/:restaurantId/limited",
        protected: true,
        component: <LimitedHome />,
        accessLevelNeeded: Limited,
    },
    operationHours: {
        title: "Restaurant Settings",
        path: "/:restaurantId/restaurant-settings/operation-hours",
        protected: true,
        accessLevelNeeded: RestaurantManager,
        component: <OperationHours />,
    },
    ttsOperationHours: {
        title: "Restaurant Settings",
        path: "/:restaurantId/restaurant-settings/tts-operation-hours",
        protected: true,
        accessLevelNeeded: RestaurantManager,
        component: <TTSOperationHours />,
    },
    restaurantSettings: {
        title: "Restaurant Settings",
        path: "/:restaurantId/restaurant-settings",
        protected: true,
        accessLevelNeeded: RestaurantManager,
        component: <RestaurantSettings />,
    },
    menuEditor: {
        title: "Menu Editor",
        path: "/:restaurantId/menu-editor",
        protected: true,
        accessLevelNeeded: RestaurantStaff,
        component: <MenuEditorWrapper />,
    },
    menuExport: {
        title: "Menu Export",
        path: "/:restaurantId/menu-version/export",
        protected: true,
        accessLevelNeeded: RestaurantManager,
        component: <MenuExport />,
    },
    menuIngestion: {
        title: "Menu Ingestion",
        path: "/:restaurantId/menu-ingestion",
        protected: true,
        accessLevelNeeded: RestaurantManager,
        component: <MenuIngestion />,
    },
    menuVersion: {
        title: "Menu Version",
        path: "/:restaurantId/menu-version/:menuType?",
        protected: true,
        accessLevelNeeded: RestaurantManager,
        component: <MenuVersion />,
    },
    itemAvailability: {
        title: "Item Availability",
        path: "/:restaurantId/item-availability",
        protected: true,
        accessLevelNeeded: RestaurantManager,
        component: <ItemAvailability />,
    },
    userManagement: {
        title: "Account Management",
        path: "/:restaurantId/user-management",
        protected: true,
        accessLevelNeeded: PrestoCustomerSupport,
        component: <UserManagementWrapper />,
    },
};

export const SIDEBAR_LINKS = (
    restaurantId: string,
    primaryRestaurantCode: string
) => {
    const replaceRestId = (path: string) =>
        path?.replace(":restaurantId", restaurantId) || "";
    const replaceMenuType = (path: string) =>
        path?.replace("/:menuType", "") || "";
    const {
        menuEditor,
        menuIngestion,
        menuVersion,
        itemAvailability,
        restaurantSettings,
        userManagement,
    } = ROUTES;
    const isMenuVersionFeatureFlagEnabled =
        readEnvVariable("FEATURE_FLAG_MENU_VERSION") === "TRUE";

    return [
        {
            href: replaceRestId(menuEditor.path),
            icon: RestaurantMenuIcon,
            title: menuEditor.title,
            accessLevelNeeded: menuEditor.accessLevelNeeded,
        },
        ...(isMenuVersionFeatureFlagEnabled && !primaryRestaurantCode
            ? [
                  {
                      href: replaceMenuType(replaceRestId(menuVersion.path)),
                      icon: HistoryIcon,
                      title: menuVersion.title,
                      accessLevelNeeded: menuVersion.accessLevelNeeded,
                  },
              ]
            : []),
        {
            href: replaceRestId(itemAvailability.path),
            icon: AvailabilityIcon,
            title: itemAvailability.title,
            accessLevelNeeded: itemAvailability.accessLevelNeeded,
        },
        {
            href: replaceRestId(restaurantSettings.path),
            icon: SettingsIcon,
            title: restaurantSettings.title,
            accessLevelNeeded: restaurantSettings.accessLevelNeeded,
        },
        {
            href: replaceRestId(userManagement.path),
            icon: BuildIcon,
            title: userManagement.title,
            accessLevelNeeded: userManagement.accessLevelNeeded,
        },
        {
            href: replaceRestId(menuIngestion.path),
            icon: LibraryAddIcon,
            title: menuIngestion.title,
            accessLevelNeeded: menuIngestion.accessLevelNeeded,
        },
    ];
};

export const findRouteWithAccess = (
    selectedRestaurantAccess: RolePermissions
) => {
    const routesInOrderOfAppearance = [
        ROUTES.menuEditor,
        ROUTES.itemAvailability,
        ROUTES.limitedHome,
    ];
    for (let route of routesInOrderOfAppearance) {
        if (
            hasRoleAccess(
                route.accessLevelNeeded || null,
                selectedRestaurantAccess
            )
        ) {
            return route;
        }
    }
    return null;
};
